<template>
    <div class="panel panel-default">
        <div class="panel-heading"></div>
        <div class="row panel-body">
            <div class="date_picker col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Start Date</label>
                <DatePicker v-model="state.optionFields.startDate.val" :input-attributes="{ class: 'form-control'}"></DatePicker>
            </div>
            <div class="date_picker col-md-3 col-sm-4 col-xs-6 form-group">
                <label>End Date</label>
                <DatePicker v-model="state.optionFields.endDate.val" :input-attributes="{ class: 'form-control' }"></DatePicker>
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>CDI Location</label>
                <select class="form-control" v-model="state.optionFields.cdiLocationId.val">
                    <option value="0">All</option>
                    <option v-for="[id, location] in cache.cdiLocationCache.CDI_LOCATIONS" v-bind:key="id" :value="id">{{ location }}</option>
                </select>
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Customer</label>
                <Typeahead cdi_type='search_my_users' @objectSelected="setCustomer" :displayValue="state.optionFields.customerName.val" v-bind:ID.sync='state.optionFields.distributorId.val' />
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Company</label>
                <Typeahead cdi_type='company' v-bind:ID.sync='state.optionFields.companyId.val' />
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Regional Sales Rep</label>
                <Typeahead cdi_type='sales_employee' v-bind:ID.sync='state.optionFields.outsideSalesId.val' />
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Sales Support Rep</label>
                <Typeahead cdi_type='sales_employee' v-bind:ID.sync='state.optionFields.insideSalesId.val' />
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
              <label>National Sales Team</label>
              <select class="form-control" v-model="state.optionFields.nationalSalesTeams.val">
                <option value="0"></option>
                <option v-for="[id, location] in nationalSalesTeams" v-bind:key="id" :value="id">{{ location }}</option>
              </select>
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Total Greater Than</label>
                <div class='input-group'>
                    <div class='input-group-addon'>$</div>
                    <input type="text" class='form-control' v-model="state.optionFields.totalGreaterThan.val">
                </div>
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Total Less Than</label>
                <div class='input-group'>
                    <div class='input-group-addon'>$</div>
                    <input type="text" class='form-control' v-model="state.optionFields.totalLessThan.val" >
                </div>
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Branch</label>
                <Typeahead cdi_type="branch" v-bind:ID.sync="state.optionFields.branchId.val" />
            </div>
            <div v-show="!toggleHide" class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Brands</label>
                <select class="form-control" v-model="state.optionFields.brand.val">
                    <option value="0">All</option>
                    <option v-for="[id, brand] in cache.brandCache.BRANDS" v-bind:key="id" :value="id">{{ brand }}</option>
                </select>
            </div>
            <div v-show="!toggleHide" class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Item</label>
                <input type=text class='form-control' v-model="state.optionFields.item.val"  >
            </div>
            <div v-show="!toggleHide" class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Customer City</label>
                <Typeahead ref="customerCity" cdi_type='city' v-bind:ID.sync='state.optionFields.distributorCityId.val' />
            </div>
            <div v-show="!toggleHide" class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Customer State</label>
                <Typeahead ref="customerState" cdi_type='state' v-bind:ID.sync='state.optionFields.distributorStateId.val' />
            </div>
            <div v-show="!toggleHide" class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Ship to State</label>
                <Typeahead ref="customerState" cdi_type='state' v-bind:ID.sync='state.optionFields.shipToStateId.val' />
            </div>
            <div v-show="!toggleHide" class="col-md-3 col-sm-4 col-xs-6 form_grid">
                <label><input type=checkbox :true-value="1" :false-value=0 v-model="state.optionFields.pendingOnly.val" > Pending Only</label>
            </div>
            <div v-show="!toggleHide" class="col-md-3 col-sm-4 col-xs-6 form_grid">
                <label><input type=checkbox :true-value="1" :false-value=0 v-model="state.optionFields.showDeadQuote.val" > Show Dead Quotes</label>
            </div>
            <div v-show="!toggleHide" class="col-md-3 col-sm-4 col-xs-6 form_grid">
                <label><input type=checkbox :true-value="1" :false-value=0 v-model="state.optionFields.showDeleted.val" > Show Deleted</label>
            </div>
            <div v-show="!toggleHide" class="col-md-3 col-sm-4 col-xs-6 form_grid">
              <label><input type=checkbox :true-value="1" :false-value=0 v-model="state.optionFields.showIntendedOrderQuotes.val" > Intended Order Date Assigned</label>
            </div>
            <div v-show="!toggleHide" class='col-xs-12 toggle_target'><div class="filter_separator">Has Item Type <small>(All if none are checked)</small></div></div>

            <div v-for="[id, curb] in cache.curbTypeCache.CURB_TYPES_HISTORICAL" v-bind:key="id" v-show="!toggleHide" class="col-md-3 col-sm-4 col-xs-6 form_grid">
                <label><input type=checkbox :value="id" :false-value=null v-model="state.optionFields.hasItemType.val"> {{ curb }}</label>
            </div>

            <div v-show="!toggleHide" class="col-md-3 col-sm-4 col-xs-6 form_grid ">
                <label><input type=checkbox :value="1" :false-value="0" v-model="state.optionFields.conforming.val" > Conforming</label>
            </div>
            <div v-show="!toggleHide" class="col-md-3 col-sm-4 col-xs-6 form_grid">
                <label><input type=checkbox :value="1" :false-value="0" v-model="state.optionFields.nonConforming.val" > Non-Conforming</label>
            </div>
            <div v-show="!toggleHide" class="col-md-3 col-sm-4 col-xs-6 form_grid">
                <label><input type=checkbox :value="1" :false-value="0" v-model="state.optionFields.applied.val" > Applied</label>
            </div>
            <div v-show="!toggleHide" class="col-md-3 col-sm-4 col-xs-6 form_grid">
                <label><input type=checkbox :value="1" :false-value="0" v-model="state.optionFields.nonApplied.val" > Non-Applied</label>
            </div>
            <div v-show="!toggleHide" class="col-md-3 col-sm-4 col-xs-6 form_grid">
                <label><input type=checkbox :value="1" :false-value="0" v-model="state.optionFields.sectional.val" > Sectional</label>
            </div>
            <div v-show="!toggleHide" class="col-md-3 col-sm-4 col-xs-6 form_grid">
                <label><input type=checkbox :value="1" :false-value="0" v-model="state.optionFields.nonSectional.val" > Non-Sectional</label>
            </div>

            <div class="col-xs-12"><button type="button" class="btn btn-default btn-sm" @click="toggleMoreFilters">{{ (toggleHide) ? 'More' : 'Less' }} Filters</button></div>
        </div>
    </div>
</template>

<script>
    import { store } from "@/store/Report.store";
    import brandCache from '@/cache/brand.cache.js';
    import cdiLocationCache from '@/cache/cdiLocation.cache';
    import curbTypeCache from '@/cache/curbType.cache.js';
    import DatePicker from "../utils/DatePicker";
    import Typeahead from "@/components/utils/typeaheads/Typeahead";

    export default {
        name: 'Options',
        components: {DatePicker, Typeahead},
        data() {
            return {
                state: store.state,
                toggleHide: true,
                cache: {
                    curbTypeCache,
                    brandCache,
                    cdiLocationCache
                },
              nationalSalesTeams: [
                [0,'Options Not Available']],
            }
        },
        created() {
          this.get_national_sales_teams();
        },
        methods: {
            toggleMoreFilters: function() {
                this.toggleHide = !this.toggleHide;
                if(this.toggleHide){
                    this.$refs.customerCity.object = '';
                    this.$refs.customerState.object = '';
                }
            },
            setCustomer: function(userData) {
                this.state.optionFields.distributorId.val = userData.id;
            },
            get_national_sales_teams: function() {
              store.customAPI('menu_builder/national_sales_teams').then(res => this.nationalSalesTeams = res);
            }
        },
        watch:{
            toggleHide: function () {
                this.state.toggleHide = this.toggleHide;
            }
        }
    }
</script>
