<template>
    <div>
        <h1>Quotes</h1>
        <form @submit.prevent="runReport">
            <Options />
            <GroupBy />
            <Fields />
            <Results />
        </form>
    </div>
</template>

<script>
    import { store } from '@/store/Report.store.js';
    import Options from './quote_rpt_opt';
    import GroupBy from './utils/GroupBy';
    import Fields from './utils/Fields';
    import Results from './utils/Results'
    export default {
        name: 'quote_rpt',
        components: {Options, Results, Fields, GroupBy},
        data() {
            return {
                state: store.state,
                optionFields: {
                    startDate:              {val: (this.$route.query.quote_start) ? this.$route.query.quote_start : new Date().toLocaleDateString(), qsField: '[dates][quote_date_from]'},
                    endDate:                {val: (this.$route.query.quote_end) ? this.$route.query.quote_end : new Date().toLocaleDateString(), qsField: '[dates][quote_date_to]'},
                    cdiLocationId:          {val: 0, qsField: '[numbers][assigned_cdi_location_id]'},
                    distributorId:          {val: (this.$route.query.outside_sales_user_id) ? this.$route.query.outside_sales_user_id : '0', qsField: '[numbers][distributor_id]'},
                    customerName:           {val: (this.$route.query.outside_sales_user) ? this.$route.query.outside_sales_user : ''},
                    companyId:              {val: 0, qsField: '[numbers][d.company_id]'},
                    outsideSalesId:         {val: 0, qsField: '[numbers][sales_user_id]'},
                    insideSalesId:          {val: 0, qsField: '[numbers][inside_sales_user_id]'},
                    nationalSalesTeams:     {val: 0, qsField: '[numbers][d.national_sales_team_id]'},
                    totalGreaterThan:       {val: '', qsField: '[gtenumbers][total]'},
                    totalLessThan:          {val: '', qsField: '[ltenumbers][total]'},
                    branchId:               {val: 0, qsField: '[numbers][b.branch_id]'},
                    brand:                  {val: 0, qsField: '[numbers][br.brand_id]', defaultVal: 0},
                    item:                   {val: '', qsField: '[fstrings][curb_txt]', defaultVal: ''},
                    distributorCityId:      {val: 0, qsField: '[strings][da.city]', defaultVal: 0},
                    distributorStateId:     {val: 0, qsField: '[numbers][st.state_id]', defaultVal: 0},
                    shipToStateId:          {val: 0, qsField: '[numbers][s.state_id]', defaultVal: 0},
                    pendingOnly:            {val: 0, qsField: '[numbers][quote_status_id]', defaultVal: 0},
                    showDeadQuote:          {val: 0, qsField: '[show_dead_quotes]', defaultVal: 0},
                    showDeleted:            {val: 0, qsField: '[show_deleted_quotes]', defaultVal: 0},
                    showIntendedOrderQuotes:      {val: 0, qsField: '[show_intended_order_quotes]', defaultVal: 0},
                    hasItemType:            {val: [], qsField: '[contains_curb_types][types]', defaultVal: []},
                    conforming:             {val: 0, qsField: '[contains_curb_types][conforming]', defaultVal: 0},
                    nonConforming:          {val: 0, qsField: '[contains_curb_types][nonconforming]', defaultVal: 0},
                    applied:                {val: 0, qsField: '[contains_curb_types][applied]', defaultVal: 0},
                    nonApplied:             {val: 0, qsField: '[contains_curb_types][nonapplied]', defaultVal: 0},
                    sectional:              {val: 0, qsField: '[contains_curb_types][sectional]', defaultVal: 0},
                    nonSectional:           {val: 0, qsField: '[contains_curb_types][nosectional]'}
                },
                groupBy: false,
                groupByFields: {
                    company_name:   {label: 'Company',          checked: false},
                    distributor:    {label: 'Customer',         checked: false},
                    outside_sales:  {label: 'Regional Rep',     checked: false},
                    inside_sales:   {label: 'Support Rep',      checked: false},
                    brand:          {label: 'Brand',            checked: false},
                    cdi_location:   {label: 'CDI Location',     checked: false},
                    city:           {label: 'Customer City',    checked: false},
                    state:          {label: 'State',            checked: false},
                    territory:      {label: 'Territory',        checked: false},
                    branch:         {label: 'Branch',           checked: false}
                },
                dataFields: {
                    quote_id:       {label: 'ID',               checked: true},
                    quote_date:     {label: 'Date',             checked: true},
                    intended_order_date:  {label: 'Intended Order Date', checked: false},
                    distributor:    {label: 'Customer',         checked: true},
                    cdi_location:   {label: 'CDI Location',     checked: false},
                    company_name:   {label: 'Company',          checked: true},
                    job_tag:        {label: 'Job Tag',          checked: true},
                    brand:          {label: 'Brand',            checked: false},
                    outside_sales:  {label: 'Regional Rep',     checked: false},
                    inside_sales:   {label: 'Support Rep',      checked: false},
                    national_sales_team: {label: 'National Sales Team', checked: false},
                    state:          {label: 'Customer State',   checked: false},
                    ship_to_state:  {label: 'Ship to State',   checked: false},
                    city:           {label: 'City',             checked: false},
                    territory:      {label: 'Territory',        checked: false},
                    curb_txt:       {label: 'Item Numbers',     checked: false},
                    branch:         {label: 'Branch',          checked: false},
                    drawings_verified: {label: 'Drawings Verified', checked: false}
                }
            }
        },
        methods: {
            runReport: function() {
                store.runReport();
            }
        },
        created() {
            this.$appStore.setTitle(['Quotes']);
            this.$set(store.state.results, this.$options.name, '');
            store.setReport(this.$options.name, this.optionFields, this.groupByFields, this.dataFields);
        }
    }

</script>
